import React from 'react';
import Header from '../header/header';
import NavBar from '../header/nav/navbar';

import fixedheaderStyles from './fixedheader.module.scss';



const Fixedheader = () => {
    return (
        <div className={fixedheaderStyles.style}>
            <div className={fixedheaderStyles.styleGreen}>
                <Header />
                <NavBar />
            </div>
        </div>
    )
}

export default Fixedheader;