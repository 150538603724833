import React, { useState } from 'react';

import { Link } from 'gatsby';
import navBarStyles from "./navbar.module.scss";
import "@fontsource/open-sans";
import styled from 'styled-components';

const MenuIcon = styled.button`
div {
    :first-child {
        transform: ${({ navmenu }) => (navmenu ? 'rotate(45deg)' : 'rotate(0)')}
    };
    :nth-child(2) {
        opacity: ${({ navmenu }) => (navmenu ? '0' : '1')}
    };
    :nth-child(3) {
        transform: ${({ navmenu }) => (navmenu ? 'rotate(-45deg)' : 'rotate(0)')}
    };
    }
`

const MenuLinks = styled.nav`
transform: ${({ navmenu }) => navmenu ? "translateY(0%)" : "translateY(-100%)"};
`


const NavBar = () => {

    const [navmenu, showNavmenu] = useState(false);

    return (
        <>
            <MenuIcon navmenu={navmenu} onClick={() => showNavmenu(!navmenu)} className={navBarStyles.btnHamburger}>
                <div />
                <div className={navBarStyles.btnHamburgerCenter} />
                <div />
            </MenuIcon>
            <MenuLinks navmenu={navmenu} className={navBarStyles.nav}>
                <ul className={navBarStyles.navList}>
                    <li>
                        <Link className={navBarStyles.navItem}
                            activeClassName={navBarStyles.active} to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className={navBarStyles.navItem}
                            activeClassName={navBarStyles.active} to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link className={navBarStyles.navItem}
                            activeClassName={navBarStyles.active} to="/projects">
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link className={navBarStyles.navItem}
                            activeClassName={navBarStyles.active} to="/contact">
                            Contact
                        </Link>
                    </li>
                </ul>
            </MenuLinks>
        </>
    )
}

export default NavBar;