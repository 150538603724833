import React from 'react';

import Fixedheader from './fixedheader';
import Footer from '../footer/footer';


import '../../styles/index.scss';
import layoutStyles from './layout.module.scss';

const Layout = (props) => {
    return (
        <div className={layoutStyles.container}>
            <div className={layoutStyles.backgroundImage}>
                <div className={layoutStyles.content}>
                    <Fixedheader />
                    <div className={layoutStyles.main}>
                        {props.children}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
export default Layout;